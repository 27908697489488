











































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Unit extends Vue {
  private name = "Stock Item";
  private modulename = "stockitem";
  private selectedItem: any = null;
  private tempItem: any = null;
  private data: any = { name: "", parent: "", unit: "", inputSetup: [], description: "", openingBalance: 0, weight: 1 };
  private inputTemp: any = { item: null, quantity: 0 };
  private selections: Array<any> = [];
  private selectAll = false;

  @Watch('mode')
  modeChanged(value: string) {
    this.clear();
  }

  @Watch("selectAll")
  selectAllChanged(value: boolean) {
    if (value) {
      const count = this.inputSetup.length;
      this.selections = [];
      for (let i = 0; i < count; i++) {
        this.selections.push(i);
      }
    } else {
      this.selections = []
    }
  }

  addSetup () {
    const sItem: any = { itemId: this.inputTemp.item, quantity: this.inputTemp.quantity }
    sItem.name = this.items.filter((i: any) => i._id.toString() === sItem.itemId.toString())[0].name;
    this.data.inputSetup.push(sItem);
    this.inputTemp = { item: null, quantity: 0 }
  }

  removeSelections () {
    const indices: Array<number> = this.selections.map(i => i);
    indices.sort((a: number, b: number) => (a > b ? -1 : 1))
    for (let i = 0; i < indices.length; i++) {
      this.data.inputSetup.splice(indices[i], 1);
    }
    this.selections = [];
    this.selectAll = false;
  }

  save () {
    if (this.mode === 'create') {
      this.$store.dispatch(`${this.modulename}/create`, { data: this.data, callback: this.onSaved });
    } else if (this.mode === 'alter') {
      const data = { ...this.data }
      const id = data._id;
      delete data._id;
      this.$store.dispatch(`${this.modulename}/patch`,{ id, data, callback: this.onSaved });
    }
  }

  remove () {
    if (this.selectedItem) {
      this.$confirm(`This will remove this ${this.name}, Do you want to continue`, "Confirm", { confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'warning'})
      .then(() => {
        this.$store.dispatch(`${this.modulename}/remove`,{ id: this.selectedItem, callback: this.onRemoved });
      })
      .catch(() => {})
    }
  }

  onSaved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = this.mode === "create" ? `${this.name} Successfully Created!` : `${this.name} Successfully Updated!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  onRemoved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = `${this.name} Successfully Removed!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  clear () {
    this.data = { name: "", parent: "", unit: "", inputSetup: [], description: "", openingBalance: 0, weight: 1 };
    this.selectedItem = null;
    this.tempItem = null;
    this.selectAll = false;
    this.selections = [];
  }

  selectObject (sitem: any) {
    this.selectedItem = sitem;
    if (this.selectedItem) {
      const item = this.items.filter((i: any) => i._id && i._id.toString() === this.selectedItem.toString())[0];
      if (item) {
        this.data = JSON.parse(JSON.stringify(item));
      }
    }
  }

  get items () {
    const items = this.$store.state[this.modulename].items;
    return items;
  }

  get units () {
    const items = this.$store.state.unit.items;
    return items;
  }

  get groups () {
    const items = this.$store.state.stockgroup.items;
    return items;
  }

  get canSave () {
    return this.data.name && this.data.parent && this.data.unit;
  }

  get canRemove () {
    return this.selectedItem
  }

  get inputSetup() {
    const items: Array<any> = this.data.inputSetup;
    const ids: Array<string> = items.map((i: any) => i.itemId.toString())
    const sitems = this.items.filter((i: any) => ids.includes(i._id.toString()))
    items.forEach((itm: any) => {
      const sitm = sitems.filter((i: any) => i._id.toString() === itm.itemId.toString())[0];
      if (sitm) {
        itm.name = sitm.name;
      } else {
        itm.name = "<NOT FOUND>";
      }
    })
    return items;
  }

  get mode () {
    const m = this.$store.state.footer.mode;
    return m;
  }

  mounted () {
    this.$store.commit('FOOTER_VISIBLE', true);
    this.$store.dispatch(`${this.modulename}/load`)
    this.$store.dispatch("unit/load")
    this.$store.dispatch("stockgroup/load")
  }

}
